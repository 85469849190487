import VueRouter from "vue-router";
import store from "./store";
const routes = [
  { path: "/", name: "/", component: () => import("@/views/HomePage.vue") },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginPage.vue"),
  },
  { path: "/buy", name: "buy", component: () => import("@/views/BuyPage.vue") },
  {
    path: "/feeds",
    name: "feeds",
    component: () => import("@/views/FeedsPage.vue"),
  },
  {
    path: "/feedform",
    name: "feedform",
    component: () => import("@/views/FeedFormPage.vue"),
  },
  {
    path: "/feedview",
    name: "feedview",
    component: () => import("@/views/FeedViewPage.vue"),
  },
  {
    path: "/topup",
    name: "topup",
    component: () => import("@/views/TopupPage.vue"),
  },
  {
    path: "/advisor",
    name: "advisor",
    component: () => import("@/views/AdvisorPage.vue"),
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import("@/views/ChangePasswordPage.vue"),
  },
  {
    path: "/editprofile",
    name: "editprofile",
    component: () => import("@/views/EditProfilePage.vue"),
  },
  {
    path: "/shoppings",
    name: "shoppings",
    component: () => import("@/views/ShoppingsPage.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfilePage.vue"),
  },
  {
    path: "/buyhistory",
    name: "buyhistory",
    component: () => import("@/views/BuyHistoryPage.vue"),
  },
  {
    path: "/registerPhone",
    name: "registerPhone",
    component: () => import("@/views/RegisterPhone.vue"),
  },
  {
    path: "/registerForm",
    name: "registerForm",
    component: () => import("@/views/RegisterForm.vue"),
  },
  {
    path: "/forgotPassword",
    name: "/forgotPassword",
    component: () => import("@/views/ForgetPassword.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: () => import("@/views/HelpPage.vue"),
  },
  {
    path: "/drawhistory",
    name: "drawhistory",
    component: () => import("@/views/DrawHistoryPage.vue"),
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/PolicyPage.vue"),
  },
  {
    path: "/myreferral",
    name: "myreferral",
    component: () => import("@/views/MyReferralPage.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/TermsPage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    ![
      "/",
      "/shoppings",
      "/login",
      "/help",
      "/topup",
      "/policy",
      "/registerPhone",
      "/registerForm",
      "/forgotPassword",
    ].includes(to.path)
  ) {
    if (!store.get("user")) {
      if (router.currentRoute.path !== "/") {
        router.replace({ path: "/" });
      }
    }
  }
  next();
});

export default router;
