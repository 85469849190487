import dayjs from "dayjs";
import forge from "node-forge";

export default {
  data: function () {
    return {
      GROUPS: [
        { animal: "ປານ້ອຍ", numbers: ["01", "41", "81"] },
        { animal: "ຫອຍ", numbers: ["02", "42", "82"] },
        { animal: "ຫ່ານ", numbers: ["03", "43", "83"] },
        { animal: "ນົກຍຸງ", numbers: ["04", "44", "84"] },
        { animal: "ສິງ", numbers: ["05", "45", "85"] },
        { animal: "ເສືອ", numbers: ["06", "46", "86"] },
        { animal: "ໝູ", numbers: ["07", "47", "87"] },
        { animal: "ກະຕ່າຍ", numbers: ["08", "48", "88"] },
        { animal: "ຄວາຍ", numbers: ["09", "49", "89"] },
        { animal: "ນາກນໍ້າ", numbers: ["10", "50", "90"] },
        { animal: "ໝາ", numbers: ["11", "51", "91"] },
        { animal: "ມ້າ", numbers: ["12", "52", "92"] },
        { animal: "ຊ້າງ", numbers: ["13", "53", "93"] },
        { animal: "ແມວບ້ານ", numbers: ["14", "54", "94"] },
        { animal: "ໜູ", numbers: ["15", "55", "95"] },
        { animal: "ເຜິ້ງ", numbers: ["16", "56", "96"] },
        { animal: "ນົກຍາງ", numbers: ["17", "57", "97"] },
        { animal: "ແມວປ່າ", numbers: ["18", "58", "98"] },
        { animal: "ແມງກະເບື້ອ", numbers: ["19", "59", "99"] },
        { animal: "ຂີ້ເຂັບ", numbers: ["00", "20", "60"] },
        { animal: "ນົກແອ່ນ", numbers: ["21", "61"] },
        { animal: "ນົກກາງແກ", numbers: ["22", "62"] },
        { animal: "ລີງ", numbers: ["23", "63"] },
        { animal: "ກົບ", numbers: ["24", "64"] },
        { animal: "ແຫຼວ", numbers: ["25", "65"] },
        { animal: "ນາກບິນ", numbers: ["26", "66"] },
        { animal: "ເຕົ່າ", numbers: ["27", "67"] },
        { animal: "ໄກ່", numbers: ["28", "68"] },
        { animal: "ອ່ຽນ", numbers: ["29", "69"] },
        { animal: "ປາໃຫຍ່", numbers: ["30", "70"] },
        { animal: "ກຸ້ງ", numbers: ["31", "71"] },
        { animal: "ງູ", numbers: ["32", "72"] },
        { animal: "ແມງມຸມ", numbers: ["33", "73"] },
        { animal: "ກວາງ", numbers: ["34", "74"] },
        { animal: "ແບ້", numbers: ["35", "75"] },
        { animal: "ເຫງັນ", numbers: ["36", "76"] },
        { animal: "ຫຼິ່ນ", numbers: ["37", "77"] },
        { animal: "ເໝັ້ນ", numbers: ["38", "78"] },
        { animal: "ກະປູ", numbers: ["39", "79"] },
        { animal: "ນົກອິນຊີ", numbers: ["40", "80"] },
      ],
      GATEWAYS: [
        {
          code: "BCEL",
          name: "BCEL One",
          namelao: "ທະນາຄານການຄ້າຕ່າງປະເທດລາວ BCELOne",
          logo: "bcel.png",
          marketid: 8,
        },
        // {
        //   code: "APB",
        //   name: "APB Mepom",
        //   namelao: "ທ/ຄ ສົ່ງເສີມກະສິກຳ",
        //   logo: "apb.png",
        //   marketid: 22,
        // },
        {
          code: "LDB",
          name: "LDB Trust",
          namelao: "ທະນາຄານພັດທະນາລາວ LDB",
          logo: "ldb.png",
          marketid: 10,
        },
        {
          code: "JDB",
          name: "JDB Yes",
          namelao: "ທະນາຄານຮ່ວມພັດທະນາ JDB",
          logo: "jdb.png",
          marketid: 20,
        },
        // {
        //   code: "IB",
        //   name: "IB Cool",
        //   namelao: "ທ/ຄ ອິນໂດຈີນ (ໄວໆນີ້)",
        //   logo: "ib.png",
        //   marketid: 21,
        // },
        // {
        //   code: "POINT",
        //   name: "ຊໍາລະດ້ວຍຄະແນນ",
        //   namelao: "ຄະແນນຂອງທ່ານ",
        //   logo: "point.png",
        //   marketid: 1,
        // },
      ],
      PRODUCTS: [
        {
          name: "ຜັກກາດ",
          price: 50000,
          photo:
            "https://img.freepik.com/free-photo/chinese-cabbage_1205-506.jpg?1&w=2000&t=st=1683444532~exp=1683445132~hmac=8ff38f25405235aedcf93b3ec849c6f7e24449dc6c0640783651563759c575a4",
        },
        {
          name: "ຜັກສະຫຼັດ",
          price: 50000,
          photo:
            "https://img.freepik.com/premium-photo/fresh-red-oak-lettuce-isolated-white_62856-3288.jpg?w=1480",
        },
        {
          name: "ຜັກກາດຂາວ",
          price: 50000,
          photo:
            "https://img.freepik.com/free-photo/chinese-cabbage-isolated-white-background_1205-505.jpg?1&w=826&t=st=1683444575~exp=1683445175~hmac=6f7b1e878c9bdeb398e18eff343517007fb8e65418db3bc2d0984152fbfe3159",
        },
        {
          name: "ກາລົດ",
          price: 50000,
          photo:
            "https://img.freepik.com/premium-photo/fresh-carrot-isolated-white_252965-130.jpg?w=1800",
        },
        {
          name: "ໝາກນາວ",
          price: 50000,
          photo:
            "https://img.freepik.com/free-photo/lime-isolated_93675-131268.jpg?w=2000&t=st=1683444880~exp=1683445480~hmac=9ecd465ecc78c85075cab8669b3a81e70a19719016c2d03e14d621a066b161e7",
        },
        {
          name: "ຜັກກາດ",
          price: 50000,
          photo:
            "https://img.freepik.com/free-photo/chinese-cabbage_1205-506.jpg?1&w=2000&t=st=1683444532~exp=1683445132~hmac=8ff38f25405235aedcf93b3ec849c6f7e24449dc6c0640783651563759c575a4",
        },
        {
          name: "ຜັກສະຫຼັດ",
          price: 50000,
          photo:
            "https://img.freepik.com/premium-photo/fresh-red-oak-lettuce-isolated-white_62856-3288.jpg?w=1480",
        },
        {
          name: "ຜັກກາດຂາວ",
          price: 50000,
          photo:
            "https://img.freepik.com/free-photo/chinese-cabbage-isolated-white-background_1205-505.jpg?1&w=826&t=st=1683444575~exp=1683445175~hmac=6f7b1e878c9bdeb398e18eff343517007fb8e65418db3bc2d0984152fbfe3159",
        },
        {
          name: "ກາລົດ",
          price: 50000,
          photo:
            "https://img.freepik.com/premium-photo/fresh-carrot-isolated-white_252965-130.jpg?w=1800",
        },
        {
          name: "ໝາກນາວ",
          price: 50000,
          photo:
            "https://img.freepik.com/free-photo/lime-isolated_93675-131268.jpg?w=2000&t=st=1683444880~exp=1683445480~hmac=9ecd465ecc78c85075cab8669b3a81e70a19719016c2d03e14d621a066b161e7",
        },
      ],
    };
  },
  filters: {
    formatnumber(x) {
      if (!x) return 0;
      if (x) {
        return (x % 1 != 0 ? x.toFixed(2) : x)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    displaydigit(x, ishide) {
      if (!x) return "";
      if (x.length === 1) x = `0${x}`;
      return (ishide ? "******" : x).substring(0, x.length);
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },
    time(x) {
      if (x) {
        return dayjs(x).format("HH:mm:ss");
      }
      return x;
    },
  },
  methods: {
    displaydigit(x, ishide) {
      if (!x) return "";
      return (ishide ? "******" : x).substring(0, x.length);
    },
    getAnimalImage(num) {
      if (!num || num === "") return "";
      if (num.length === 1) num = `0${num}`;
      return `animals/${this.getAnimalName(num)}.png`;
    },
    getAnimalName(num) {
      let num2 = num;
      if (num2.length >= 2) {
        num2 = num2.slice(-2);
      } else {
        num2 = "0" + num2;
      }

      const match = this._.find(this.GROUPS, (g) => {
        return g.numbers.includes(num2);
      });
      return match ? match.animal : "";
    },
    formatnumber(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "";
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },
    async sign(data, privateKeyPem, flutter) {
      if (!privateKeyPem) {
        try {
          privateKeyPem = await flutter.callHandler("readsecure", "privateKey");
        } catch (error) {
          console.error("ERROR", JSON.stringify(error));
          console.error(error.stack);
          privateKeyPem = await flutter.callHandler("getdata", "privateKey");
        }
      }

      let publicKeyPem = null;
      try {
        publicKeyPem = await flutter.callHandler("readsecure", "publicKey");
      } catch (error) {
        publicKeyPem = await flutter.callHandler("getdata", "publicKey");
      }

      try {
        const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
        const md = forge.md.sha512.create();
        md.update(data, "utf8");
        const signature = forge.util.encode64(privateKey.sign(md));
        const v = await this.verifySignature(
          data,
          signature,
          publicKeyPem,
          flutter
        );
        console.log("veri", v);
        return signature;
      } catch (error) {
        console.error(JSON.stringify(error));
        console.error(error.stack);
        return null;
      }
    },
    async verifySignature(data, signatureBase64, publicKeyPem, flutter) {
      if (!publicKeyPem) {
        try {
          publicKeyPem = await flutter.callHandler("readsecure", "publicKey");
        } catch (error) {
          publicKeyPem = await flutter.callHandler("getdata", "publicKey");
        }
      }
      try {
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        const md = forge.md.sha512.create();
        md.update(data, "utf8");
        const signature = forge.util.decode64(signatureBase64);
        return publicKey.verify(md.digest().bytes(), signature);
      } catch (error) {
        console.error(JSON.stringify(error));
        console.error(error.stack);
        return false;
      }
    },
    generateRandom(characters, length) {
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    randomCharacter(length) {
      return this.generateRandom(
        `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`,
        length
      );
    },
    randomNumber(length) {
      return this.generateRandom(`0123456789`, length);
    },
    getflutter() {
      let wait = 0;
      while (!window["flutter_inappwebview"]) {
        setTimeout(() => {}, 100);
        if (wait++ >= 100) {
          break;
        }
      }
      return window["flutter_inappwebview"];
    },
    getmachinekey(device) {
      if (!device) return "";
      let output = "";
      if (device["devicetype"]) {
        output += device["devicetype"];
      }
      if (device["devicename"]) {
        output += device["devicename"];
      }
      if (device["deviceversion"]) {
        output += device["deviceversion"];
      }
      if (device["deviceidentifier"]) {
        if (
          !device["deviceidentifier"] ||
          device["deviceidentifier"] === "" ||
          device["deviceidentifier"] === "unknown" ||
          device["deviceidentifier"] === "UNKNOWN"
        ) {
          device["deviceidentifier"] =
            device["firebasetokenid"] ??
            device["huaweitoken"] ??
            this.randomCharacter(10);
        }
        output += device["deviceidentifier"];
      }
      if (device["all"]) {
        try {
          const all = JSON.parse(device["all"]);
          if (all["androidId"]) {
            if (all["androidId"] === "unknown") {
              all["androidId"] =
                device["firebasetokenid"] ?? this.randomCharacter(10);
            }
            output += all["androidId"];
          }
        } catch (error) {
          console.error(error);
        }
      }
      if (window["flutter_inappwebview"]) {
        window["flutter_inappwebview"].callHandler(
          "setdata",
          "machinekey",
          output
        );
      }
      return output;
    },
    isnumber(str) {
      return /^\d+$/.test(str);
    },
  },
};
